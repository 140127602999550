document.addEventListener('DOMContentLoaded', () => {
  const tableWraps = document.querySelectorAll('.product-card__detail-description > div');

  if (tableWraps.length) {
    tableWraps.forEach((wrap) => {
      if (wrap.querySelector('table')) {
        const wrapParent = document.createElement('div');
        wrap.parentElement.insertBefore(wrapParent, wrap);
        wrapParent.append(wrap);
        wrapParent.classList.add('shadow-right');

        wrap.style.overflowX = 'auto';
        wrap.style.position = 'relative';

        wrap.addEventListener('scroll', () => {
          if (wrap.scrollLeft <= 0) {
            wrapParent.classList.remove('shadow-left');
          } else if (wrap.scrollLeft >= wrap.scrollWidth - wrap.offsetWidth) {
            wrapParent.classList.remove('shadow-right');
          } else {
            wrapParent.classList.add('shadow-right');
            wrapParent.classList.add('shadow-left');
          }
        });
      }
    });
  }
});
