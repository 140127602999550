import { hideBackground } from './background';

document.addEventListener('DOMContentLoaded', () => {
  const search = document.querySelector('.header-search');
  const isOpenSearch = document.querySelector('.js-open-search');

  if (search) {
    const searchButton = search.querySelector('.header-search__button');
    const searchForm = document.querySelector('.header-search__form');
    const header = document.querySelector('.header');
    const menu = header.querySelector('.header-menu');
    const burger = header.querySelector('.header-burger');

    if (isOpenSearch) {
      searchForm.classList.add('visible');
      searchForm.classList.add('header-search__form--border');
    } else {
      searchButton.addEventListener('click', () => {
        searchForm.classList.toggle('visible');

        burger.classList.remove('header-burger--active');
        menu.classList.remove('visible');
        hideBackground();
      });

      document.addEventListener('click', (e) => {
        if (searchForm.classList.contains('visible')
          && !e.target.closest('.header-search__form')
          && !e.target.closest('.header-search__button')
        ) {
          searchForm.classList.remove('visible');
        }
      });
    }
  }
});
