export default function ajaxSend($this) {
  let responseNode = $this.querySelector('#response-message');

  $.ajax({
    url: $this.action + ($this.action.includes('?') ? '&' : '?') + 'ajaxForm=true&web_form_submit=Отправить',
    data: new FormData($this),
    type: 'post',
    beforeSend: () => {
      $this.querySelector('[type="submit"]').setAttribute('disabled', 'true');
      responseNode.innerHTML = '';
      responseNode.style.setProperty('display', 'none');
    },
    processData: false,
    contentType: false,
    success: (data) => {
      responseNode.style.setProperty('display', 'flex');
      responseNode.innerHTML = data.markup
      return true;
    },
    complete: () => {
      $this.querySelector('[type="submit"]').removeAttribute('disabled');
    }
  });
}
