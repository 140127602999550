import countAnimation from './helpers/countAnimation';
import isVisible from './helpers/isVisible';

const timelines = document.querySelectorAll('.js-timeline');
const counters = document.querySelectorAll('.js-timeline-counter');

if (timelines.length > 0) {
  counters.forEach((counter) => {
    counter.dataset.year = Number(counter.innerText);
    counter.innerText = 0;
  });

  const onScroll = () => {
    counters.forEach(async (counter) => {
      const subTitle = counter.parentElement.querySelector('.js-timeline-sub-title');

      if (isVisible(counter)) {
        await countAnimation(counter);

        if (subTitle) {
          subTitle.classList.add('timeline-item__sub-title--visible');
        }
      }
    });

    if (window.innerWidth <= 767) return;

    timelines.forEach((timeline) => {
      const items = timeline.querySelectorAll('.js-timeline-item');

      items.forEach((item) => {
        if (isVisible(item, true)) {
          item.classList.add('timeline-item--scrolled');
        } else {
          item.classList.remove('timeline-item--scrolled');
        }
      });
    });
  };

  onScroll();

  document.addEventListener('scroll', onScroll);
}
