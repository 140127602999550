import Form from "./helpers/validate";
import ajaxSend from "./helpers/ajax";

document.addEventListener("DOMContentLoaded", () => {
  const isEn = window.SITE_LANG !== "s1";
  const form = new Form(document.querySelectorAll("form"), {
    focusValidate: true,
    fields: [
      {
        selector: ".js-name",
        maxLength: 32,
        realTimeRegExp: "text",
        realTime: true,
        required: true,
        errorText: isEn ? "Name is incorrect" : "Некорректно введено имя",
      },
      {
        selector: ".js-phone",
        realTimeRegExp: "phone",
        regExp: "phone",
        realTime: true,
        required: true,
        mask: "+7 (***) ***-**-**",
        errorText: isEn ? "Phone is incorrect" : "Некорректный номер телефона",
      },
      {
        selector: ".js-email",
        realTimeRegExp: "email",
        realTime: true,
        required: true,
        regExp: "email",
        errorText: isEn ? "Email is incorrect" : "Некорректный e-mail",
      },
    ],
  });

  form.on("submit", (e) => {
    const $this = e.target;

    if ($this.classList.contains("ajax")) {
      e.preventDefault();
      ajaxSend($this);
    }
  });
});
