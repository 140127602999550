import Swiper from 'swiper';
import wow from './animation';

function mainPageSliderInit() {
  const swiper = new Swiper('.main-page-slider__container', {
    slidesPerView: 1,
    direction: 'vertical',
    mousewheel: true,
    speed: 700,
    simulateTouch: false,
    pagination: {
      el: '.main-page-slider-pagination',
      clickable: true,
      bulletClass: 'main-page-slider-pagination__bullet',
      bulletActiveClass: 'main-page-slider-pagination__bullet--active',
    },
  });

  swiper.on('slideChangeTransitionEnd', () => {
    const slides = document.querySelectorAll('.main-page-slider__container .swiper-slide');
    const scrollButton = document.querySelector('.js-scroll-next');
    const rateButton = document.querySelector('.js-rate');

    if (slides[slides.length - 1].classList.contains('swiper-slide-active')) {
      scrollButton.classList.add('hide');
      rateButton.classList.remove('hide');
    } else {
      scrollButton.classList.remove('hide');
      rateButton.classList.add('hide');
    }

    if (document.querySelectorAll('.main-page-slider__container .swiper-slide-active .wow').length) {
      document.querySelectorAll('.main-page-slider__container .swiper-slide-active .wow').forEach((item) => {
        wow.show(item);
      });
    }
  });

  return swiper;
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.main-page-slider__container')) {
    const scrollButton = document.querySelector('.js-scroll-next');

    let mainPageSlider = null;

    if (window.innerWidth >= 1280) {
      mainPageSlider = mainPageSliderInit();
    }

    let timeout;
    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (window.innerWidth >= 1280 && mainPageSlider === null) {
          mainPageSlider = mainPageSliderInit();
        } else if (window.innerWidth < 1280 && mainPageSlider instanceof Swiper) {
          mainPageSlider.destroy(true, true);
          mainPageSlider = null;
        }
      }, 50);
    });

    if (scrollButton) {
      scrollButton.addEventListener('click', () => {
        mainPageSlider.slideNext();
      });
    }
  }
});
