const onButtonClick = (button, blocks) => {
  blocks.forEach((block) => block.classList.remove('production-all__phone-hidden'));
  button.style.display = 'none';
};

document.addEventListener('DOMContentLoaded', () => {
  const containers = document.querySelectorAll('.js-mobile-hide');

  if (containers.length > 0) {
    containers.forEach((container) => {
      const blocks = container.querySelectorAll('.js-mobile-hide-block');
      const button = container.querySelector('.js-mobile-hide-button button');

      if (blocks.length === 0 || !button) return false;

      if (blocks.length < 5) {
        button.style.display = 'none';
        return false;
      }

      blocks.forEach((block, index) => {
        if (index > 4) {
          block.classList.add('production-all__phone-hidden');
        }
      });

      button.addEventListener('click', (e) => onButtonClick(e.target, blocks));
    });
  }
});
