import { hideBackground, showBackground } from './background';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.header');
  const menu = header.querySelector('.header-menu');
  const burger = header.querySelector('.header-burger');
  const searchForm = document.querySelector('.header-search__form');
  const isOpenSearch = document.querySelector('.js-open-search');

  if (menu) {
    const submenuList = menu.querySelectorAll('.header-menu__submenu');
    const firstLevelLinks = menu.querySelectorAll('.header-menu__link');

    menu.classList.remove('hide');

    if (submenuList.length) {
      submenuList.forEach((submenu) => {
        const item = submenu.closest('li');
        const link = item.querySelector('a');

        link.classList.add('arrow');

        if (item.classList.contains('header-menu__item')) {
          item.addEventListener('mouseenter', (e) => {
            if (window.innerWidth >= 1280) {
              e.preventDefault();

              submenu.classList.add('visible');

              showBackground();

              if (searchForm && !isOpenSearch) {
                searchForm.classList.remove('visible');
              }
            }
          });

          item.addEventListener('mouseleave', (e) => {
            if (window.innerWidth >= 1280) {
              e.preventDefault();

              submenu.classList.remove('visible');
              hideBackground();
            }
          });
        }

        link.addEventListener('click', (e) => {
          if (window.innerWidth < 1280) {
            if (!submenu.classList.contains('visible')) {
              e.preventDefault();
            }

            submenu.classList.toggle('visible');
            link.classList.toggle('active');
          }
        });
      });

      let timeout;
      window.addEventListener('resize', () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          if (window.innerWidth > 1280) {
            menu.querySelectorAll('.header-menu__submenu.visible').forEach((submenu) => {
              submenu.classList.remove('visible');
            });
          }
        }, 50);
      });
    }

    menu.querySelectorAll('.header-menu__item').forEach((item) => {
      const link = item.querySelector('a');

      item.addEventListener('mouseenter', () => {
        if (window.innerWidth >= 1280) {
          firstLevelLinks.forEach((link) => link.classList.add('header-menu__link--hover'));
          link.classList.remove('header-menu__link--hover');
          link.classList.add('header-menu__link--invert');
        }
      });

      item.addEventListener('mouseleave', (e) => {
        if (window.innerWidth >= 1280) {
          firstLevelLinks.forEach((link) => link.classList.remove('header-menu__link--hover'));
          link.classList.remove('header-menu__link--invert');
        }
      });
    });
  }

  // burger menu
  if (menu && burger) {
    burger.addEventListener('click', () => {
      if (searchForm && !isOpenSearch) {
        searchForm.classList.remove('visible');
      }

      if (menu.classList.contains('visible')) {
        burger.classList.remove('header-burger--active');
        menu.classList.remove('visible');
        hideBackground();
      } else {
        burger.classList.add('header-burger--active');
        menu.classList.add('visible');
        showBackground();
      }
    });
  }
});
