import gsap from 'gsap';
import debounce from './helpers/debounce';

const tickers = document.querySelectorAll('.js-ticker');

const startAnimation = (text, clonedText, duration) => gsap
  .timeline({
    repeat: -1,
    defaults: {
      duration,
      ease: 'linear',
      repeat: -1,
    },
  })
  .to(text, {
    x: text.offsetWidth,
  })
  .to(clonedText, {
    x: clonedText.offsetWidth,
  }, `-=${duration / 2}`)
  .progress(0.5);

document.addEventListener('DOMContentLoaded', () => {
  if (tickers.length > 0 && window.innerWidth > 1279) {
    tickers.forEach((ticker) => {
      const text = ticker.querySelector('.js-ticker-text');
      const clonedText = text.cloneNode(true);

      ticker.append(clonedText);

      const duration = 80;

      let animation = startAnimation(text, clonedText, duration);

      const debouncedOnScroll = debounce(() => {
        animation.timeScale(3);
        setInterval(() => {
          animation.timeScale(1);
        }, 200);
      }, 0, true);

      const debouncedOnResize = debounce(() => {
        animation.kill();

        animation = startAnimation(text, clonedText, duration);
      }, 500);

      document.addEventListener('scroll', debouncedOnScroll);
      // window.addEventListener('resize', debouncedOnResize);
    });
  }
});
