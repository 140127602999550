import gsap from 'gsap';

window.onload = () => {
  const partners = document.querySelector('.partners-slider__wrapper');
  const partner = document.querySelector('.partners-slider__items');

  if (!(partner && partners)) {
    return false;
  }

  for (let i = 0; i < 3; i++) {
    partners.append(partner.cloneNode(true));
  }

  const duration = 20;
  gsap
    .timeline({
      defaults: {
        duration,
        ease: 'none',
        repeat: -1,
      },
    })
    .fromTo(
      partners,
      { x: -partner.offsetWidth * 2 },
      { x: -partner.offsetWidth },
    );
};
