import bodyScroll from './helpers/bodyScroll';

document.addEventListener('DOMContentLoaded', () => {
  const videos = document.querySelectorAll('.video');

  if (videos.length) {
    videos.forEach((video) => {
      const button = video.querySelector('button');

      const iframe = document.querySelector(`#${button.dataset.id}`);
      const popup = iframe.closest('.video-popup');

      button.addEventListener('click', () => {
        popup.classList.add('visible');
        bodyScroll.disable();
        play(iframe);
      });

      popup.addEventListener('click', (e) => {
        if (!e.target.closest('.popup__content') && popup.classList.contains('visible')) {
          popup.classList.remove('visible');
          bodyScroll.enable();
          pause(iframe);
        }
      });
    });
  }
});

const tag = document.createElement('script');

tag.src = 'https://player.vimeo.com/api/player.js';
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const vimeoContainers = document.querySelectorAll('.vimeo-video');
const vimeoPlayers = [];

if (vimeoContainers) {
  window.addEventListener('load', () => {
    initVimeoPlayers(vimeoContainers);
  });
}

function getVPByID(id) {
  return vimeoPlayers.filter((item) => item.amPlayerId === id)[0];
}

function initVimeoPlayers(videos, events = []) {
  videos.forEach((item) => {
    const { videoId } = item.dataset;
    const elemId = item.id;

    if (!getVPByID(videoId)) {
      const player = new Vimeo.Player(elemId, {
        id: videoId,
      });

      player.amPlayerId = videoId;
      player.amType = 'vimeo';

      if (events) {
        events.forEach((event) => {
          player.on(event.name, event.func);
        });
      }

      vimeoPlayers.push(player);
    }
  });
}

function pause(video) {
  const { videoId } = video.dataset;
  const player = getVPByID(videoId);

  if (player) {
    player.pause();
  }
}

function play(video) {
  const { videoId } = video.dataset;
  const player = getVPByID(videoId);

  if (player) {
    player.play();
  }
}

export {
  getVPByID, initVimeoPlayers, pause, play,
};
