import gsap from 'gsap';

const MIN_SCALE = 0.4;
const MIN_SCALE_TABLET = 0.3;

const BASE_X_OFFSET = 90;
const BASE_X_OFFSET_TABLET = 90;

export default class ImageSlider {
  get scale() {
    if (window.innerWidth < 1279) return MIN_SCALE_TABLET;
    return MIN_SCALE;
  }

  get xOffset() {
    if (window.innerWidth < 1279) return BASE_X_OFFSET_TABLET;
    return BASE_X_OFFSET;
  }

  get thumbsCount() {
    if (window.innerWidth <= 1279 && window.innerWidth > 767) return 2;
    return 1;
  }

  get additionalThumb() {
    const additionalThumbIndex = this.items.indexOf(this.nextElement) + 1;
    const additionalThumb = this.items[additionalThumbIndex];
    return this.items.length > 3 ? additionalThumb || this.items[0] : this.prevElement;
  }

  set activeIndex(value) {
    this._activeIndex = value;
  }

  get activeIndex() {
    return this._activeIndex;
  }

  /**
   * @return {Element[]} Все елементы, которые не next, prev и current
   */
  get otherElements() {
    const hiddenElements = [...this.items];
    hiddenElements.splice(hiddenElements.indexOf(this.currentElement), 1);
    hiddenElements.splice(hiddenElements.indexOf(this.nextElement), 1);
    hiddenElements.splice(hiddenElements.indexOf(this.prevElement), 1);

    return hiddenElements;
  }

  /**
   * @return {Element} следующий в очереди элемент
   */
  get nextElement() {
    const element = this.items[this.activeIndex + 1];
    if (element) { return element; }
    return this.items[0];
  }

  /**
   * @return {Node} предыдущий в очереди элемент
   */
  get prevElement() {
    const element = this.items[this.activeIndex - 1];
    if (element) { return element; }
    return this.items[this.items.length - 1];
  }

  /**
   * @return {Node} активный элемент
   */
  get currentElement() {
    return this._currentElement;
  }

  set currentElement(value) {
    this._currentElement = value;
    this.activeIndex = this.items.indexOf(this.currentElement);
  }

  /**
   * @param {NodeListOf<Element>} items
   */
  constructor(items) {
    this.tl = gsap.timeline({
      defaults: {
        duration: 0.5,
      },
    });

    this.items = [...items];
    this.finishedElement = items[items.length - 1];
    [this.currentElement] = items;

    this.setInitialStyles();
  }

  setInitialStyles() {
    // Назначая нужные стили, располагаем элементы по порядку
    this.items.forEach((el, index) => this.tl.set(el, {
      xPercent: index * this.xOffset,
      yPercent: -50,
      scale: this.scale,
      opacity: 0,
    }));

    this.tl.set(this.currentElement, {
      scale: 1,
      opacity: 1,
    });

    this.tl.set([this.nextElement, ...(this.thumbsCount > 1 ? [this.additionalThumb] : [])], {
      opacity: 1,
      scale: this.scale,
    });

    if (this.thumbsCount > 1) {
      this.tl.set(this.additionalThumb, {
        xPercent: this.xOffset + 40,
      });
    }
  }

  /**
   * Переключает слайд вперёд
   */
  slideToForward() {
    const foundedEl = this.items[this.activeIndex + 1];

    if (foundedEl) {
      this.currentElement = foundedEl;
    } else {
      [this.currentElement] = this.items;
    }

    this.moveForwardAnimation();
  }

  /**
   * Переключает слайдер в начальную позиция
   */
  toFirstFromEnd() {
    this.completed = false;

    [this.currentElement] = this.items;

    this.toFirstFromEndAnimation();
  }

  /**
   * Переключает слайд Назад
   */
  slideToBack() {
    const foundedEl = this.items[this.activeIndex - 1];

    if (foundedEl) {
      this.currentElement = foundedEl;
    } else {
      this.currentElement = this.items[this.items.length - 1];
    }

    this.moveBackAnimation();
  }

  /**
   * Анимация перехода в начало слайдера
   */
  toFirstFromEndAnimation() {
    this.tl.to(this.prevElement, { xPercent: -100, opacity: 0, scale: this.scale })
      .set(this.prevElement, {
        scale: this.scale,
        xPercent: (this.items.length - 1) * 100,
        opacity: 0,
      });

    this.tl.to(this.currentElement, { scale: 1, xPercent: 0, opacity: 1 }, '-=0.45');

    this.tl.to(this.nextElement, { opacity: 1, xPercent: this.xOffset }, '-=0.35');

    if (this.thumbsCount > 1) {
      this.tl.set(this.additionalThumb, { opacity: 1, xPercent: 240 }, '-=0.35');
      this.tl.to(this.additionalThumb, { opacity: 1, xPercent: this.xOffset + 40 }, '-=0.35');
    }
  }

  /**
   * Анимация "Вперёд"
   */
  moveForwardAnimation() {
    if (this.items.length === 2) {
      // анимация для двух элементов
      this.tl.to(this.prevElement, { xPercent: -100, opacity: 0 })
        .set(this.prevElement, {
          scale: this.scale,
          xPercent: (this.items.length - 1) * 100,
          opacity: 0,
        });

      this.tl.to(this.currentElement, { scale: 1, xPercent: 0 }, '-=0.45');

      this.tl.to(this.prevElement, { opacity: 1, xPercent: this.xOffset });

      return true;
    }

    this.tl.to(this.prevElement, { xPercent: -100, opacity: 0 })
      .set(this.prevElement, {
        scale: this.scale,
        xPercent: (this.items.length - 1) * 100,
        opacity: 0,
      });

    this.tl.to(this.currentElement, { scale: 1, xPercent: 0 }, '-=0.45');

    this.tl.to(this.nextElement, { opacity: 1, xPercent: this.xOffset }, '-=0.35');

    if (this.thumbsCount > 1) {
      this.tl.set(this.additionalThumb, { opacity: 0, xPercent: this.xOffset + 65, scale: this.scale }, '-=0.3');
      this.tl.to(this.additionalThumb, { opacity: 1, xPercent: this.xOffset + 40 }, '-=0.2');
    }

    return true;
  }

  /**
   * Анимация "Назад"
   */
  moveBackAnimation() {
    if (this.items.length === 2) {
      // Анимация для двух элементов
      this.tl.to(this.currentElement, { opacity: 0, xPercent: 200 });

      this.tl.set(this.currentElement, { xPercent: -100, opacity: 0 })
        .to(this.currentElement, { scale: 1, xPercent: 0, opacity: 1 });

      this.tl.to(this.nextElement, { scale: this.scale, xPercent: this.xOffset }, '-=0.8');

      return true;
    }

    if (this.thumbsCount > 1) {
      this.tl.to(this.currentElement, { opacity: 0, xPercent: this.xOffset + 60, duration: '0.25' });
    }

    this.tl.set(this.currentElement, { xPercent: -100, opacity: 0 })
      .to(this.currentElement, { scale: 1, xPercent: 0, opacity: 1 });

    this.tl.to(this.nextElement, { scale: this.scale, xPercent: this.xOffset }, '-=0.45');

    if (this.thumbsCount === 1) {
      this.tl.to([this.prevElement, ...this.otherElements], { opacity: 0, xPercent: 200 }, '-=0.35');
    } else {
      this.tl.to(this.additionalThumb, { xPercent: this.xOffset + 40 }, '-=0.35');

      const secondThumbIndex = this.items.indexOf(this.additionalThumb) + 1;
      const secondThumb = this.items[secondThumbIndex] || this.items[0];

      if (this.items.length > 3) { this.tl.to(secondThumb, { opacity: 0, xPercent: 200 }, '-=0.35'); }
    }
  }

  /**
   * Прокручивает слайдер к индексу
   * @param {number} index - Индекс элемента, к которому нужно прокрутить слайдер
   */
  slideTo(index) {
    const step = index - this.activeIndex;

    if (step === 0) { return false; }

    if (step > 0) {
      for (let i = 0; i < step; i++) {
        this.slideToForward();
      }
    }

    if (step < 0) {
      for (let i = 0; i < -step; i++) {
        this.slideToBack();
      }
    }
  }

  /**
   * Вешает событие на клик по элементу
   * @param {function(number)} callback - callback с индексом элемента, по которому кликнули
   */
  onElementClick(callback) {
    this.items.forEach((item, index) => item.addEventListener('click', (e) => {
      callback(index);
    }));
  }
}
