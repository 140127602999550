import gsap from 'gsap';
import Swiper from 'swiper';
import ImageSlider from './ImageSlider';

const startProgressAnimation = (el) => {
  const progressAnimation = gsap.timeline();
  progressAnimation.remove(el);
  progressAnimation.set(el, { xPercent: -100, yPercent: 50 });
  progressAnimation.to(el, {
    xPercent: 0,
    duration: 5,
    ease: 'linear',
  });

  return progressAnimation;
};

document.addEventListener('DOMContentLoaded', () => {
  const slider = document.querySelector('.slider');
  const sliderItems = document.querySelectorAll('.slider-main__slide');
  const pagination = document.querySelector('.slider-main-nav');
  let progressAnimation;

  if (slider && sliderItems.length > 1) {
    const images = slider.querySelectorAll('.slider__image-wrap');

    // Слайдер для картинок
    const imageSlider = new ImageSlider(images);

    const progress = slider.querySelectorAll('.slider-main-nav__pagination-progress-inner');
    const sliderMain = slider.querySelector('.slider-main');
    const prevButton = slider.querySelector('.slider-main-nav__button--prev');
    const nextButton = slider.querySelector('.slider-main-nav__button--next');
    // ellipses
    const ellipses = slider.querySelectorAll('.slider-main__ellipse path');
    const ellipseFirst = ellipses[0];
    const ellipseSecond = ellipses[1];
    let ellipseFirstDeg = -30;
    let ellipseSecondDeg = 0;
    // pagination
    const slidesNum = slider.querySelectorAll('.slider-main__slide').length;
    const paginationCurElem = slider.querySelector('.slider-main-nav__pagination-text--cur');
    const paginationNextElem = slider.querySelector('.slider-main-nav__pagination-text--max');

    const sliderMainSwiper = new Swiper(sliderMain, {
      slidesPerView: 1,
      effect: 'fade',
      loop: true,
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 500,

      on: {
        init: () => { progressAnimation = startProgressAnimation(progress); },
      },
    });

    imageSlider.onElementClick((index) => {
      sliderMainSwiper.slideNext();
    });

    prevButton.addEventListener('click', () => {
      sliderMainSwiper.slidePrev();
    });

    nextButton.addEventListener('click', () => {
      sliderMainSwiper.slideNext();
    });

    sliderMainSwiper.on('slidePrevTransitionStart', () => {
      ellipseFirstDeg -= 360;
      ellipseSecondDeg -= 360;

      ellipseFirst.style.transform = `rotate(${ellipseFirstDeg}deg)`;
      ellipseSecond.style.transform = `rotate(${ellipseSecondDeg}deg)`;
    });

    sliderMainSwiper.on('slideNextTransitionStart', () => {
      ellipseFirstDeg += 360;
      ellipseSecondDeg += 360;

      ellipseFirst.style.transform = `rotate(${ellipseFirstDeg}deg)`;
      ellipseSecond.style.transform = `rotate(${ellipseSecondDeg}deg)`;

      imageSlider.slideToForward();
    });

    sliderMainSwiper.on('slidePrevTransitionStart', () => {
      imageSlider.slideToBack();
    });

    sliderMainSwiper.on('slideChangeTransitionStart', () => {
      const { realIndex } = sliderMainSwiper;

      progressAnimation.restart();

      const slidesDone = realIndex + 1;
      const nextSlide = slidesDone + 1;
      paginationCurElem.innerHTML = slidesDone < 10 ? `0${slidesDone}` : slidesDone;
      paginationNextElem.innerHTML = nextSlide > slidesNum ? '01' : (nextSlide < 10 ? `0${nextSlide}` : nextSlide);
    });
  } else if (pagination) {
    pagination.style.display = 'none';
  }
});
