import BodyScroll from './helpers/bodyScroll';

export function showBackground() {
  if (document.querySelector('.dark-background')) {
    document.querySelector('.dark-background').classList.add('visible');
  }

  if (document.querySelector('.white-background')) {
    document.querySelector('.white-background').classList.add('visible');
  }
  BodyScroll.disable();
}

export function hideBackground() {
  if (document.querySelector('.dark-background')) {
    document.querySelector('.dark-background').classList.remove('visible');
  }

  if (document.querySelector('.white-background')) {
    document.querySelector('.white-background').classList.remove('visible');
  }
  BodyScroll.enable();
}
