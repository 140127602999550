import Swiper from 'swiper';

const partnersSlider = new Swiper('.card-slider', {
  slidesPerView: 1,
  loop: true,
  navigation: {
    nextEl: '.card-slider__next',
    prevEl: '.card-slider__prev',
  },
  pagination: {
    el: '.card-slider__pagination',
    type: 'bullets',
    bulletClass: 'card-slider__pagination-bullet',
    bulletActiveClass: 'card-slider__pagination-bullet_active',
    clickable: true
  },
});
