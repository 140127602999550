import gsap, { Expo } from 'gsap';

export default function countAnimation(el) {
  return new Promise((resolve) => {
    if (el.classList.contains('js-counter-started')) { return; }

    const num = Number(el.dataset.year);
    const animateObj = { num: num / 2 };

    el.classList.add('js-counter-started');

    gsap.to(animateObj, {
      num,
      duration: 3,

      ease: Expo.easeOut,
      onUpdate: () => {
        el.innerText = Math.floor(animateObj.num);
      },
    })
      .then(() => resolve());
  });
}
